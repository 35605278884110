import { run } from './function';

export function randomString(size: number, radix: number = 36, result: string = ''): string {
  if (result.length >= size) {
    return result.slice(0, size);
  }

  return randomString(size, radix, `${result}${Math.random().toString(radix).slice(2)}`);
}

export const randomUUID = /* @__PURE__ */ run(() => {
  if (typeof crypto === 'object' && typeof crypto.randomUUID === 'function') {
    return () => {
      return crypto.randomUUID();
    };
  }

  return () => {
    return `xxxxxxxx-xxxx-4xxx-Nxxx-xxxxxxxxxxxx`.replace(/N|x+/g, (value) => {
      return value === 'N'
        ? // eslint-disable-next-line no-bitwise
          ((((Math.random() * 16) | 0) & 0x3) | 0x8).toString(16)
        : randomString(value.length, 16);
    });
  };
});

export const matchAll = /* @__PURE__ */ run(() => {
  if (typeof String.prototype.matchAll === 'function') {
    return (text: string, regexp: RegExp) => {
      return text.matchAll(regexp);
    };
  }

  return function* matchAll(text: string, regexp: RegExp) {
    let result;

    do {
      result = regexp.exec(text);

      if (result) {
        yield result as RegExpMatchArray;
      }
    } while (result);
  };
});

import type { Path } from './types';
import { EMPTY_ARRAY, EMPTY_FUNCTION } from './lang';

export function run<T>(fn: () => T) {
  return fn();
}

export function disposable(fn: () => void) {
  let disposed = false;

  const dispose = () => {
    if (!disposed) {
      fn();

      disposed = true;

      return true;
    }

    return false;
  };

  return dispose;
}

export function createHandlerProxy<T extends (path: Path, ...args: any[]) => any>(
  handle: T,
  path: Path = EMPTY_ARRAY,
): unknown {
  return new Proxy(EMPTY_FUNCTION, {
    get(_, key) {
      return createHandlerProxy(handle, [...path, key]);
    },
    apply(_, __, args) {
      return handle(path, ...(args as Parameters<T>));
    },
  });
}
